<template>
  <div>
    <AppDialog
      v-model="isShowDialog"
      persistent
      size="large"
      overflow-height="calc(100vh - 270px)"
      title="添加人员到团体测评"
      color="primary"
      action-icon="mdi-account-multiple-plus"
      action-text="确认添加"
      cancel-text="关闭"
      @confirm="assignPickedPersonList"
    >
      <AppLoadingSpinner v-model="isLoading" />
      <div>第一步：选择测评单位（可多选）</div>
      <v-form ref="nodeSelectForm" class="mx-4 d-flex align-center flex-wrap">
        <v-select
          label="测评单位"
          multiple
          chips
          deletable-chips
          :items="allNodeList"
          v-model="selectedNodeGuids"
          :rules="fieldRules.required"
        ></v-select>
        <AppTooltipBtn
          btn-class="ml-8"
          color="primary"
          icon="mdi-magnify"
          label="搜索"
          @click="confirmAndGetPersonList"
        />
      </v-form>
      <div class="mt-4 d-flex justify-space-between align-center">
        第二步：确认要添加的人员（只显示未添加过的人员）
        <v-text-field
          class="person-search-field"
          v-model="personSearchText"
          append-icon="mdi-magnify"
          label="搜索"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </div>
      <v-data-table
        class="mb-n5"
        :headers="assignPersonHeaders"
        :height="getDataTableHeight()"
        :loading="isDataTableLoading"
        :items="assignPersonList"
        item-key="guid"
        :custom-filter="dataCustomFilter"
        :search="personSearchText"
        show-select
        :single-select="false"
        v-model="pickedPersonList"
        loading-text="正在读取人员列表，请稍候..."
        no-data-text="选择测评单位，然后点击“搜索”获取人员列表"
        no-results-text="未找到匹配的人员"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageAllText: '所有',
          itemsPerPageText: '每页数量：',
          itemsPerPageOptions: [5, 10, 20, 50, 100]
        }"
      >
        <template
          v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
        >
          {{
            `共有${itemsLength}个人员可添加 ${selectedPersonCountText} 本页显示第${pageStart}-${pageStop}`
          }}
        </template>
        <template v-slot:[`item.loginIdentity`]="{ item }">
          <v-chip v-if="item.loginIdentity" small>
            {{
              `${item.loginIdentity.loginKeyType.typeText}: ${item.loginIdentity.loginKey}`
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.resultsList`]="{ value }">
          <div v-for="(res, rIdx) in value" :key="rIdx">
            <v-tooltip
              bottom
              v-for="(alert, aIdx) in res.alertList"
              :key="aIdx"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="!!alert.displayName"
                  :color="`${alert.colorHex}`"
                  v-on="on"
                  v-bind="attrs"
                >
                  mdi-record
                </v-icon>
              </template>
              <span>{{ `${res.lbDispName} ${alert.displayName}` }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </AppDialog>
    <AppDialog v-model="isAssigning" title="正在添加..." persistent>
      <div>请稍候。正在添加参加本次团体测评的人员...</div>
      <v-progress-linear rounded indeterminate height="6"></v-progress-linear>
    </AppDialog>
    <AppMessageBox title="添加成功！" v-model="assignSuccessMessage" />
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppDialog from "@/components/AppDialog";
import { fetchManageNodeFlatSelectList } from "@/api/manageNode";
import { fetchTestPersonList } from "@/api/person";
import {
  fetchLbGroupPersonList,
  assignPersonListToLbGroup
} from "@/api/lbGroup";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppTooltipBtn,
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    regionGuid: {
      type: String
    },
    nodeGuid: {
      type: String
    },
    lbGroupGuid: {
      type: String
    },
    lbGroupName: {
      type: String
    }
  },

  model: {
    prop: "value",
    event: "change"
  },

  data() {
    return {
      isLoading: false,
      isDataTableLoading: false,
      isAssigning: false,
      isShowDialog: false,
      errorMsg: "",
      uploadErrorMsg: "",
      assignSuccessMessage: "",
      // assign node
      allNodeList: [],
      selectedNodeGuids: [],
      fieldRules: {
        required: [val => (val || "").length > 0 || "必填"]
      },
      // assign person list
      assignPersonHeaders: [
        { text: "姓名", value: "name" },
        { text: "性别", value: "gender" },
        { text: "年龄", value: "age" },
        { text: "识别号", value: "loginIdentity" },
        { text: "预警", value: "resultsList", filterable: false }
      ],
      assignPersonList: [],
      pickedPersonList: [],
      personSearchText: ""
    };
  },

  watch: {
    value(newVal) {
      this.isShowDialog = newVal;
    },
    isShowDialog(newVal) {
      this.$emit("change", newVal);
      if (newVal) {
        this.prepareNodeList();
      } else {
        this.resetSelectedData();
        this.$emit("closed");
      }
    },
    personSearchText() {
      // 搜索时清空选中
      this.pickedPersonList = [];
    }
  },

  computed: {
    selectedPersonCountText() {
      if (this.pickedPersonList && this.pickedPersonList.length) {
        return `，选中了${this.pickedPersonList.length}个`;
      }
      return "";
    }
  },

  methods: {
    resetSelectedData() {
      this.personSearchText = "";
      this.selectedNodeGuids = [];
      this.assignPersonList = [];
      this.pickedPersonList = [];
      this.$refs.nodeSelectForm.resetValidation();
    },
    async prepareNodeList() {
      try {
        this.isLoading = true;
        this.allNodeList = await fetchManageNodeFlatSelectList(
          this.regionGuid,
          [this.nodeGuid]
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    // ============================ DataTable fit =================================
    getNodeSelectFormHeight() {
      if (this.$refs.nodeSelectForm) {
        return this.$refs.nodeSelectForm.offsetHeight;
      }
      return 0;
    },
    getDataTableHeight() {
      var overflowHeight = 480 + this.getNodeSelectFormHeight();
      return `calc(100vh - ${overflowHeight}px)`;
    },
    // ============================ Data custom filter =================================
    dataCustomFilter(value, search) {
      if (value && search) {
        let valToFilter = value.loginKey || value.toString();
        return valToFilter.indexOf(search) > -1;
      }
      return false;
    },
    // ============================ select node guids ============================
    async confirmAndGetPersonList() {
      if (this.selectedNodeGuids && this.selectedNodeGuids.length) {
        try {
          this.isDataTableLoading = true;
          // LbGroup 中不做筛选
          let rawPersonListInfo = await fetchTestPersonList(
            this.regionGuid,
            this.selectedNodeGuids
          );
          let rawPersonList = rawPersonListInfo.personList;
          let existPersonList = await fetchLbGroupPersonList(this.lbGroupGuid);
          if (existPersonList && existPersonList.length) {
            rawPersonList = rawPersonList.filter(
              raw =>
                !existPersonList.find(
                  p => p.person.personUniGuid === raw.testPerson.personUniGuid
                )
            );
          }
          this.assignPersonList = rawPersonList.map(p => {
            return {
              ...p.testPerson,
              resultsList: p.testResultDispList
            };
          });
          // 默认全选
          this.pickedPersonList = this.assignPersonList;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isDataTableLoading = false;
      }
    },
    // ============================ assign person list ============================
    async assignPickedPersonList() {
      if (this.pickedPersonList && this.pickedPersonList.length) {
        try {
          this.isAssigning = true;
          let pickedPersonGuids = this.pickedPersonList.map(p => p.guid);
          let assignCount = await assignPersonListToLbGroup(
            this.lbGroupGuid,
            pickedPersonGuids
          );
          this.assignSuccessMessage = `成功添加 ${assignCount} 个被试人员到 ${this.lbGroupName}`;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isAssigning = false;
        // 分配完后关闭窗口
        this.isShowDialog = false;
      }
    }
  },

  created() {
    this.prepareNodeList();
  }
};
</script>

<style lang="scss" scoped>
.person-search-field {
  max-width: 200px;
}
</style>
