export const lbGroupType = {
  open: {
    value: "open",
    text: "开放测评"
  },
  group: {
    value: "group",
    text: "团体测评"
  },
  individual: {
    value: "individual",
    text: "个人测评"
  }
};
