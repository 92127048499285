import { get, post } from "@/utils/axios";

export function fetchQrInfoSettings(lbGroupGuid) {
  return get(`/qrcodeinfo/GetQrCodeSettings/${lbGroupGuid}`);
}

export function generateQrCode({ lbGroupGuid, qrTitle, queryList }) {
  return post("/qrcodeinfo/MakeQrCode", {
    lbGroupGuid,
    urlPath: `${process.env.VUE_APP_PUBLIC_PATH}scalogin`,
    queryList,
    textEx: qrTitle
  });
}

export async function updateQrInfoSettings(qrInfoGuid, { expireSeconds }) {
  let response = await post("/qrcodeinfo/UpdateQrSettings", {
    qrInfoGuid,
    expireSeconds
  });
  return response.guid;
}

export async function uploadQrCodeIcon(qrInfoGuid, iconImage) {
  let formData = new FormData();
  formData.append("iconImage", iconImage, iconImage.name);
  let response = await post(
    `/qrcodeinfo/UploadQrCodeIcon/${qrInfoGuid}`,
    formData
  );
  return response.guid;
}
