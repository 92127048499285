<template>
  <div>
    <AppDialog
      v-model="isShowDialog"
      persistent
      size="x-large"
      overflow-height="calc(100vh - 270px)"
      :title="`${lbGroupName}测评详情`"
      color="primary"
      cancel-text="关闭"
    >
      <AppLoadingSpinner v-model="isLoading" />
      <template v-slot:title-right>
        <v-progress-linear
          v-if="completeProgress"
          :value="completeProgress * 100"
          color="primary"
          rounded
          height="4"
        >
        </v-progress-linear>
      </template>
      <v-card-text class="d-flex justify-space-around">
        <v-chip label>
          <v-icon left>
            mdi-clipboard-list-outline
          </v-icon>
          {{ `测评总量：${totalAssignedCount}` }}
        </v-chip>
        <v-chip label>
          <v-icon left>
            mdi-clipboard-account-outline
          </v-icon>
          {{ `测评人数：${assignedPersonCount}` }}
        </v-chip>
        <v-chip label>
          <v-icon left>
            mdi-clipboard-check-outline
          </v-icon>
          {{ `已完成的数量：${completedLbCount}` }}
        </v-chip>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title ref="cardTitle">
        被试人员
        <AppTooltipBtn
          btn-class="ml-4"
          rounded
          color="primary"
          icon="mdi-file-excel-outline"
          label="导出"
          @click="exportSelectedToExcel"
        />
        <v-checkbox
          class="ml-4"
          v-model="isExcludeCompleted"
          label="只显示未完成人员"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-text-field
          class="person-search-field"
          v-model="personSearchText"
          append-icon="mdi-magnify"
          label="搜索"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="mb-n5"
        :height="getDataTableHeight()"
        :headers="lbGroupPersonHeaders"
        :items="lbGroupPersonList"
        item-key="person.guid"
        :custom-filter="dataCustomFilter"
        :search="personSearchText"
        show-select
        :single-select="false"
        v-model="selectedPersonList"
        loading-text="正在读取被试人员列表，请稍候..."
        no-data-text="还未添加任何被试人员到此团体测评中。请点击团体测评列表中的“配置人员”按钮进行添加。"
        no-results-text="未找到匹配的人员"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageAllText: '所有',
          itemsPerPageText: '每页数量：',
          itemsPerPageOptions: [5, 10, 20, 50, 100]
        }"
      >
        <template
          v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
        >
          {{
            `共有${itemsLength}个被试人员 ${selectedPersonCountText} 本页显示第${pageStart}-${pageStop}`
          }}
        </template>
        <template v-slot:[`item.personCompleteCount`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-progress-linear
                v-if="item.personAssignedCount > 0"
                :value="
                  (item.personCompleteCount / item.personAssignedCount) * 100
                "
                color="primary"
                rounded
                height="20"
                v-on="on"
                v-bind="attrs"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.floor(value) }}%</strong>
                </template>
              </v-progress-linear>
            </template>
            <span>
              {{
                `量表完成进度：${item.personCompleteCount}/${item.personAssignedCount}`
              }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.person.loginIdentity`]="{ value }">
          <v-chip v-if="value && value.loginKey" small>
            {{ `${value.loginKeyType.typeText}: ${value.loginKey}` }}
          </v-chip>
        </template>
        <template v-slot:[`item.person.guid`]="{ item }">
          <AppTooltipBtn
            color="error"
            icon="mdi-link-variant-remove"
            tooltip="从本次测评中移除"
            @click="showUnbindPersonDialog(item)"
          />
        </template>
      </v-data-table>
    </AppDialog>
    <AppDialog
      v-model="isShowUnbindPersonDialog"
      color="error"
      size="small"
      :title="`是否确定要从此次测评中移除 ${actionPersonName} ？`"
      action-text="确认移除"
      :loading="isDialogLoading"
      @confirm="unbindOnePerson"
      @closed="unbindPersonDialogClosed"
    >
      移除后可再次添加
    </AppDialog>
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppDialog from "@/components/AppDialog";
import {
  fetchLbGroupDetails,
  fetchLbGroupPersonList,
  unbindLbGroupFromPerson
} from "@/api/lbGroup";
import { getUserFieldConfig } from "@/api/fieldConfig";
import { downloadPersonListExcel } from "@/api/person";
import { downloadFile } from "@/utils/download";
import { getDateFromDateTime } from "@/utils/dateTime";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppTooltipBtn,
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    regionGuid: {
      type: String
    },
    nodeGuid: {
      type: String
    },
    lbGroupGuid: {
      type: String
    },
    lbGroupName: {
      type: String
    }
  },

  model: {
    prop: "value",
    event: "change"
  },

  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      isShowDialog: false,
      errorMsg: "",
      fieldConfigList: [],
      lbGroupDetails: {},
      lbGroupPersonHeaders: [],
      lbGroupPersonList: [],
      personSearchText: "",
      isExcludeCompleted: false,
      actionPersonUniGuid: "",
      actionPersonName: "",
      selectedPersonList: [],
      isShowUnbindPersonDialog: false
    };
  },

  watch: {
    value(newVal) {
      this.isShowDialog = newVal;
    },
    isShowDialog(newVal) {
      this.$emit("change", newVal);
      if (newVal) {
        this.refreshLbGroupMonitorDetails();
      } else {
        this.resetMonitorData();
        this.$emit("closed");
      }
    },
    personSearchText() {
      // 搜索时清空选中
      this.selectedPersonList = [];
    },
    isExcludeCompleted() {
      this.refreshLbGroupMonitorDetails();
    }
  },

  computed: {
    assignedLbCount() {
      if (this.lbGroupDetails && this.lbGroupDetails.assignLbCount) {
        return this.lbGroupDetails.assignLbCount;
      }
      return "";
    },
    assignedPersonCount() {
      if (this.lbGroupDetails && this.lbGroupDetails.assignPersonCount) {
        return this.lbGroupDetails.assignPersonCount;
      }
      return "";
    },
    completedLbCount() {
      if (this.lbGroupDetails && this.lbGroupDetails.completedLbCount) {
        return this.lbGroupDetails.completedLbCount;
      }
      return "";
    },
    completeProgress() {
      if (this.lbGroupDetails && this.lbGroupDetails.completeProgress) {
        return this.lbGroupDetails.completeProgress;
      }
      return "";
    },
    totalAssignedCount() {
      if (this.assignedLbCount && this.assignedPersonCount) {
        return this.assignedLbCount * this.assignedPersonCount;
      }
      return "";
    },
    selectedPersonCountText() {
      if (this.selectedPersonList && this.selectedPersonList.length) {
        return `，选中了${this.selectedPersonList.length}个`;
      }
      return "";
    }
  },

  methods: {
    // ============================ DataTable fit =================================
    getCardTitleHeight() {
      if (this.$refs.cardTitle) {
        return this.$refs.cardTitle.offsetHeight;
      }
      return 0;
    },
    getDataTableHeight() {
      var overflowHeight = 395 + this.getCardTitleHeight();
      return `calc(100vh - ${overflowHeight}px)`;
    },
    // ============================ Data custom filter =================================
    dataCustomFilter(value, search) {
      if (value && search) {
        let valToFilter = value.loginKey || value.toString();
        return valToFilter.indexOf(search) > -1;
      }
      return false;
    },
    // ============================ Prepare data =================================
    resetMonitorData() {
      this.personSearchText = "";
      this.isExcludeCompleted = false;
      this.actionPersonUniGuid = "";
      this.actionPersonName = "";
      this.selectedPersonList = [];
      this.lbGroupDetails = {};
      this.lbGroupPersonList = [];
    },
    async refreshLbGroupMonitorDetails() {
      if (this.lbGroupGuid) {
        try {
          this.isLoading = true;
          this.fieldConfigList = await getUserFieldConfig([this.nodeGuid]);
          this.buildPersonListHeaders();
          this.lbGroupDetails = await fetchLbGroupDetails(this.lbGroupGuid);
          let personList = await fetchLbGroupPersonList(this.lbGroupGuid);
          if (this.isExcludeCompleted) {
            personList = personList.filter(
              p => p.personCompleteCount / p.personAssignedCount < 1
            );
          }
          this.lbGroupPersonList = personList;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    },
    buildPersonListHeaders() {
      this.lbGroupPersonHeaders = [];
      for (let fieldConfig of this.fieldConfigList) {
        // 不显示 NodeGuid
        if (
          fieldConfig.isVisibleInTable &&
          fieldConfig.fieldName !== "nodeGuid"
        ) {
          this.lbGroupPersonHeaders.push({
            text: fieldConfig.fieldAlias,
            value: `person.${fieldConfig.fieldName}`
          });
        }
      }
      this.lbGroupPersonHeaders.push(
        {
          text: "识别号",
          value: "person.loginIdentity",
          sort: (id1, id2) => {
            if (!id1) return -1;
            if (!id2) return 1;
            return id1.loginKey > id2.loginKey ? 1 : -1;
          }
        },
        {
          text: "完成进度",
          value: "personCompleteCount"
        },
        {
          text: "操作",
          value: "person.guid",
          sortable: false,
          filterable: false
        }
      );
    },
    async exportSelectedToExcel() {
      if (this.selectedPersonList && this.selectedPersonList.length) {
        try {
          this.isLoading = true;
          let selectedPersonGuids = this.selectedPersonList.map(
            p => p.person.guid
          );
          let downloadPath = await downloadPersonListExcel({
            sheetTitle: "导出的被试人员",
            regionGuid: this.regionGuid,
            nodeGuid: this.nodeGuid,
            personGuidList: selectedPersonGuids
          });
          downloadFile(
            downloadPath,
            `被试人员导出_${getDateFromDateTime(new Date().toISOString())}.xlsx`
          );
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    },
    showUnbindPersonDialog(personObj) {
      this.actionPersonUniGuid = personObj.person.personUniGuid;
      this.actionPersonName = personObj.person.name;
      this.isShowUnbindPersonDialog = true;
    },
    unbindPersonDialogClosed() {
      this.actionPersonUniGuid = "";
      this.actionPersonName = "";
    },
    async unbindOnePerson() {
      if (this.actionPersonUniGuid) {
        try {
          this.isDialogLoading = true;
          await unbindLbGroupFromPerson(
            this.lbGroupGuid,
            this.actionPersonUniGuid
          );
          this.refreshLbGroupMonitorDetails();
          this.isShowUnbindPersonDialog = false;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isDialogLoading = false;
      }
    }
  },

  created() {
    this.refreshLbGroupMonitorDetails();
  }
};
</script>

<style lang="scss" scoped>
.person-search-field {
  max-width: 200px;
}
</style>
